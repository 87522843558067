export const Welcome = () => {
  return (
    <div className="welcome">
      <h2>Welcome to my website!</h2>
      <span>
        My name is Réka and I have experience in application/web UX, UI Design, Research, and Marketing. <br />
        <br />I worked 3 years in Web Design, creative marketing, and event management for a technology company, where I learned how to work and communicate with developers efficiently.
        <br />
        <br />I would describe myself as a curious person with an eye for detail and a thirst for knowledge. I believe in self-improvement so my goal with a job is to be able to learn from it as much
        as possible, and enjoy the process! I also like to bake, and kickbox, and fun fact at one point I was a professional opera singer.
      </span>
    </div>
  );
};
