export const Download = () => {
  return (
    <div className="download">
      <h2>Download my full CV and portfolio here:</h2>
      <a href="https://drive.google.com/drive/mobile/folders/1rQYpeTOHPaPg-djW1SH5xcJuN7cjtcln?pli=1" target="_blank" className="portfolio-link">
        Open in Google drive
      </a>
    </div>
  );
};
