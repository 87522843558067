import React, { useEffect, useRef, useMemo } from "react";
import { useAnimations, useFBX, useGLTF, useScroll } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function Avatar(props) {
  const { animation } = props;
  const group = useRef();
  const { nodes, materials } = useGLTF("models/rekaavatar.glb", true);
  const { animations: flyingAnimation } = useFBX("animations/Flying.fbx");
  const { animations: waveAnimation } = useFBX("animations/Waving.fbx");
  waveAnimation[0].name = "Waving";
  flyingAnimation[0].name = "Flying";
  const scroll = useScroll();

  const { actions } = useAnimations([waveAnimation[0], flyingAnimation[0]], group);

  useEffect(() => {
    const action = actions[animation ?? "Waving"];
    action.reset().play();
  }, [actions, animation]);

  useFrame(() => {
    if (!group.current) return;
    group.current.rotation.y = -scroll.offset;

    const offset = 1 - scroll.offset * 7;
    group.current.scale.set(offset, offset, offset);
    group.current.rotation.x = scroll.offset * 6;
    group.current.rotation.y = scroll.offset * 3;
    group.current.rotation.z = -scroll.offset * 4;

    if (scroll.offset > 0.08) {
      actions.Flying.play();
      actions.Waving.stop();
      const x = scroll.offset * 2;
      const y = scroll.offset;
      group.current.scale.set(0.3, 0.3, 0.3);
      group.current.position.set(x, y, 0);
      group.current.rotation.set(-y, x, 0);
    } else {
      group.current.position.set(0, 0, 0);
      actions.Waving.play();
      actions.Flying.stop();
    }
  });

  const skinnedMeshes = useMemo(
    () => [
      {
        name: "Wolf3D_Head",
        geometry: nodes.Wolf3D_Head.geometry,
        material: materials.Wolf3D_Skin,
        skeleton: nodes.Wolf3D_Head.skeleton,
        morphTargetDictionary: nodes.Wolf3D_Head.morphTargetDictionary,
        morphTargetInfluences: nodes.Wolf3D_Head.morphTargetInfluences,
      },
      {
        name: "Wolf3D_Teeth",
        geometry: nodes.Wolf3D_Teeth.geometry,
        material: materials.Wolf3D_Teeth,
        skeleton: nodes.Wolf3D_Teeth.skeleton,
        morphTargetDictionary: nodes.Wolf3D_Teeth.morphTargetDictionary,
        morphTargetInfluences: nodes.Wolf3D_Teeth.morphTargetInfluences,
      },
      {
        name: "EyeLeft",
        geometry: nodes.EyeLeft.geometry,
        material: materials.Wolf3D_Eye,
        skeleton: nodes.EyeLeft.skeleton,
        morphTargetDictionary: nodes.EyeLeft.morphTargetDictionary,
        morphTargetInfluences: nodes.EyeLeft.morphTargetInfluences,
      },
      {
        name: "EyeRight",
        geometry: nodes.EyeRight.geometry,
        material: materials.Wolf3D_Eye,
        skeleton: nodes.EyeRight.skeleton,
        morphTargetDictionary: nodes.EyeRight.morphTargetDictionary,
        morphTargetInfluences: nodes.EyeRight.morphTargetInfluences,
      },
      {
        geometry: nodes.Wolf3D_Hair.geometry,
        material: materials.Wolf3D_Hair,
        skeleton: nodes.Wolf3D_Hair.skeleton,
      },
      {
        geometry: nodes.Wolf3D_Body.geometry,
        material: materials.Wolf3D_Body,
        skeleton: nodes.Wolf3D_Body.skeleton,
      },
      {
        geometry: nodes.Wolf3D_Outfit_Bottom.geometry,
        material: materials.Wolf3D_Outfit_Bottom,
        skeleton: nodes.Wolf3D_Outfit_Bottom.skeleton,
      },
      {
        geometry: nodes.Wolf3D_Outfit_Footwear.geometry,
        material: materials.Wolf3D_Outfit_Footwear,
        skeleton: nodes.Wolf3D_Outfit_Footwear.skeleton,
      },
      {
        geometry: nodes.Wolf3D_Outfit_Top.geometry,
        material: materials.Wolf3D_Outfit_Top,
        skeleton: nodes.Wolf3D_Outfit_Top.skeleton,
      },
    ],
    [nodes, materials]
  );

  return (
    <group {...props} ref={group} dispose={null}>
      <group rotation-x={-Math.PI / 2}>
        <primitive object={nodes.Hips} />
        {skinnedMeshes.map((mesh, index) => (
          <skinnedMesh
            key={index}
            name={mesh.name}
            geometry={mesh.geometry}
            material={mesh.material}
            skeleton={mesh.skeleton}
            morphTargetDictionary={mesh.morphTargetDictionary}
            morphTargetInfluences={mesh.morphTargetInfluences}
          />
        ))}
      </group>
    </group>
  );
}

useGLTF.preload("models/rekaavatar.glb");
