import React from "react";
export const Projects = () => {
  return (
    <>
      <h2>Projects</h2>
      <div className="project project-1">
        <h3>TRAVL project</h3>
        <div>Your ultimate travel guide, program planner, and hotel booking buddy!</div>
        <a href="/projects/travl">Read more</a>
      </div>

      <div className="project project-2">
        <h3>D8 project</h3>
        <div>
          D8 is about meeting people online without the heavy feeling that you’re shopping, it is about slowing down the pace of relationship development so that people are encouraged to focus on
          individuals and not always be looking at the next person.
        </div>
        <a href="/projects/d8">Read more</a>
      </div>

      <div className="project project-3">
        <h3>Flight.co project</h3>
        <div>In this project, I designed a website that fits all the criteria to make the first step of your travel experience an easy and enjoyable process.</div>
        <a href="/projects/flight">Read more</a>
      </div>
    </>
  );
};
