import React from "react";
import { Home } from "./pages/Home.jsx";

export const App = () => {
  return (
    <>
      <a href="/">
        <div className="title">
          <h1>Réka Sápi</h1>
          <span>UX/UI Designer</span>
        </div>
      </a>
      <Home />
    </>
  );
};
