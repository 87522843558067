import React, { useMemo } from "react";
import { Avatar } from "./Avatar";
import { ScrollControls, Scroll, Float, useFont, PresentationControls } from "@react-three/drei";
import { useLoader, useThree } from "@react-three/fiber";
import { useMediaQuery } from "react-responsive";
import { Welcome } from "./Welcome";
import { Download } from "./Download";
import { Projects } from "./Projects";
import * as THREE from "three";

export function Experience() {
  const colorMap = useLoader(THREE.TextureLoader, "Travertine001_1K-JPG_Color.jpg");
  const colorMap2 = useLoader(THREE.TextureLoader, "Travertine013_1K-JPG_Color.jpg");
  const { viewport } = useThree();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const sphereGeometry = useMemo(() => new THREE.SphereGeometry(0.5, 32, 32), []);
  const material1 = useMemo(() => new THREE.MeshStandardMaterial({ map: colorMap, roughness: 0.7, metalness: 0.5 }), [colorMap]);
  const material2 = useMemo(() => new THREE.MeshStandardMaterial({ map: colorMap2, roughness: 0.7, metalness: 0.5 }), [colorMap2]);

  return (
    <Float rotationIntensity={0.5}>
      <ScrollControls pages={isMobile ? 3.5 : 3} damping={0.1} distance={1} horizontal={false}>
        <group position-x={-viewport.width / 4} position-y={-1}>
          <Avatar />
        </group>
        <Scroll>
          <group position-x={viewport.width / 2.8} position-y={1 - viewport.height * 2.3} position-z={-1}>
            <PresentationControls>
              <mesh geometry={sphereGeometry} material={material1} />
            </PresentationControls>
          </group>
          <group position-x={1 - viewport.width / 1.6} position-y={1 - viewport.height * 1.4} position-z={-1}>
            <PresentationControls>
              <mesh geometry={sphereGeometry} material={material2} />
            </PresentationControls>
          </group>
        </Scroll>

        <Scroll html>
          <section>
            <Welcome />
          </section>
          <section>
            <Projects />
          </section>
          <section>
            <Download />
          </section>
        </Scroll>
      </ScrollControls>
      <ambientLight intensity={0.5} />
      <directionalLight position={[5, 5, 5]} intensity={1} />
    </Float>
  );
}

useFont.preload("./fonts/Atma_Regular.json");
