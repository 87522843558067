import { Canvas } from "@react-three/fiber";
import { Experience } from "../components/Experience.jsx";
import { Loader, Stars } from "@react-three/drei";

export const Home = () => {
  return (
    <div className="canvas-container">
      <Canvas shadows camera={{ position: [0, 2, 5], fov: 30 }}>
        <Stars radius={100} depth={50} count={5000} factor={10} saturation={0} fade speed={2} />
        <Experience />
      </Canvas>
      <Loader />
    </div>
  );
};
